@import '../../sass/functions';

.main {
	flex: 1 0 auto;
	position: relative;
	overflow: hidden;
	padding-top: em(70);
	&_contacts {
		& + .footer {
			border: none;
		}
	}
	@media (min-width: 751px) {
		&_f {
			min-height: em(895);
		}
		&__full {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			padding-top: em(70);
			.section {
				height: 100%;
				display: flex;
				flex-direction: column;
				.wrp {
					width: 100%;
				}
				.wrp:last-child {
					margin-top: auto;
				}
			}
		}
	}
}
