@import '../../../sass/variables';
@import '../../../sass/functions';
@import '../../../sass/extends';

.textarea{
	display: block;
	width: 100%;
	height: em(164);
	padding: em(20) em(20);
	border: 1px solid $bk;
	background: none;
	transition: .21s;
	resize: none;
	overflow: auto;
	line-height: 1.3;
	&::-webkit-scrollbar {
		width: r(8);
	}
	&::-webkit-scrollbar-track {
		background: $gy;
		border-radius: r(8);
	}
	&::-webkit-scrollbar-thumb {
		background: $txt;
		border-radius: r(8);
	}
	&:hover,
	&:focus{
		box-shadow: 2px 1px 21px rgba(0,0,0,.21);
	}
}

.wrap{
	position: relative;
}

.placeholder{
	display: inline-flex;
	align-items: center;
	height: em(64);
	padding: 0 em(20);
	border: 1px solid transparent;
	line-height: normal;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2;
	max-width: 100%;
	z-index: 2;
	pointer-events: none;
}