@import '../../../sass/variables';
@import '../../../sass/functions';
@import '../../../sass/extends';

.toggle{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    cursor: pointer;
    &__label{
        padding-right: em(30);
        line-height: (23/16);
        flex: 0 1 auto;
        transition: .21s;
    }
    &__button{
        display: block;
        flex: 0 0 em(51);
        max-width: em(51);
        height: em(30);
        background: #EEEEEE;
        border-radius: em(30);
        position: relative;
        &:after{
            @extend %ab;
            width: em(22);
            height: em(22);
            background: $whBg;
            border-radius: 50%;
            box-shadow: 0 em(2) em(2) rgba(50, 50, 71, 0.06), 0 em(2) em(2) rgba(50, 50, 71, 0.06);
            position: absolute;
            top: 50%;
            margin-top: em(-11);
            left: em(4);
            z-index: 2;
            transition: .3s;
        }
    }
    &__input{
        position: absolute;
        left: -9999px;
        &:checked + span{
            &:after{
                transform: translateX(em(20));
                background: $bk;
            }
        }
    }
    &:hover{
        .toggle__label{
            opacity: .7;
        }
    }
}