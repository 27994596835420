@import './../../sass/variables';

.lds-ring {
	display: inline-block;
	position: relative;
	width: 100vw;
	height: 100vh;
	.wrp &{
		width: 100%;
		height: 90px;
	}
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: $txt transparent transparent transparent;

	&:nth-child(1) {
		animation-delay: -0.45s;
	}

	&:nth-child(2) {
		animation-delay: -0.3s;
	}

	&:nth-child(3) {
		animation-delay: -0.15s;
	}
}

@keyframes lds-ring {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}
