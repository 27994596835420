// font family
$ff: 'Open Sans', sans-serif;
$fr: 'Roboto', sans-serif;

// colors
$bk: #000000;
$wh: #ffffff;
$gn: #00ff00;
$be: #0000ff;
$yw: #ffff00;
$vt: #895df7;

$gy: #BBBBBB;
$rd: #FF005C;
$lgy: #DDDDDD;
$txt: #303030;
$whTxt: #FAFAFA;
$gyTxt: #818181;
$whBg: #FAFAFA;