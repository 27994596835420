$gutter: 24;
$wrapper: 1200;

.wrp{
    margin: 0 auto;
    max-width: rem($wrapper + $gutter);
    padding: 0 rem($gutter / 2);
    @media(max-width: 1000px){
        padding: 0 24px;
    }
}

.row{
    display: flex;
    flex: 1 1 100%;
    flex-wrap: wrap;
    margin: 0 rem($gutter / -2);
    &_wrp{
        margin: 0 auto;
        max-width: rem($wrapper + $gutter);
    }
    &_nw{
        flex-wrap: nowrap;
    }
    &_column{
        flex-direction: column;
    }
    &_col-center{
        justify-content: center;
    }
    &_col-between{
        justify-content: space-between;
    }
    &_col-middle{
        align-items: center;
    }
    &_col-bottom{
        align-items: flex-end;
    }
    &-5cols{
        .col{
            flex: 0 0 20%;
            max-width: 20%;
        }
    }
    &_gap10{
        margin: 0 rem(-5);
    }
    &_gap60{
        margin: 0 rem(-30);
    }
    @media(max-width: 1200px){
        &-md_col-center{
            justify-content: center;
        }
    }
    @media(min-width: 1001px){
        &_md-x-nw{
            flex-wrap: nowrap;
        }
    }
    @media(max-width: 1000px){
        margin-left: $gutter * -1px;
        margin-right: $gutter * -1px;
        &_wrp{
            margin-left: 15px - $gutter / 2;
            margin-right: 15px - $gutter / 2;
        }
        &_sm-2cols{
            .col{
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
        &-sm_col-center{
            justify-content: center;
        }
    }
    @media(min-width: 751px){
        &_sm-x-nw{
            flex-wrap: nowrap;
        }
    }
}

@mixin cols {
    flex: 0 1 auto;
    max-width: none;
    padding: 0 rem($gutter / 2);
    
    &-fill{
        flex: 1 1 100%;
        max-width: none;
        padding: 0 rem($gutter / 2);
    }
    
    $cC: 12;
    @for $i from 1 through $cC {
        &-#{$i}{
            flex: 0 0 (100 / $cC) * $i * 1%;
            max-width: (100 / $cC) * $i * 1%;
            padding: 0 rem($gutter / 2);
            .row_gap10 > &{
                padding: 0 rem(5);
            }
            .row_gap60 > &{
                padding: 0 rem(30);
            }
            @if $i == $cC {
                margin-left: 0;
                margin-right: 0;
            }
        }
        &-offset-#{$i}{
            margin-left: (100 / $cC) * $i * 1%;
        }
    }
    
    &_center{
        margin-left: auto;
        margin-right: auto;
    }
    &_middle{
        align-self: center;
    }
    &_right{
        margin-left: auto;
    }
    &_grow{
        flex-grow: 1;
    }
    &_first{
        order: -1;
    }
}

.col{
    @include cols;
}

@media(max-width: 1600px){
    .lg{
        &-col{
            @include cols;
        }
    }
}

@media(max-width: 1200px){
    .md{
        &-row{
            &-col-fw{
                & > [class*="col"]{
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
        &-col{
            @include cols;
        }
    }
}

@media(max-width: 1000px){
    .sm{
        &-col{
            @include cols;
        }
    }
    .row{
        margin-left: -5px;
        margin-right: -5px;
        &_wrp{
            margin-left: 19px;
            margin-right: 19px;
        }
        &:not(.row_nw):not(.row_sm-2cols) > [class*="col"]:not([class*="sm-col-"]){
            flex: 0 0 100%;
            max-width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
        &_sm-2cols{
            .col{
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
        & > [class*="col"]{
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}

@media(max-width: 750px){
    .xs{
        &-col{
            @include cols;
        }
    }
    .row{
        &_xs-2cols{
            .col{
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
}