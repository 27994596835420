@import '../../sass/functions';
@import '../../sass/extends';
@import '../../sass/variables';

.step{
    padding-right: em(70);
    position: relative;
    margin: em(20) 0;
    &__image{
        width: em(321);
        height: em(168);
        padding-bottom: em(20);
        img{
            @extend %fw-img;
        }
    }
    &__title{
        font-size: em(28);
        font-weight: 600;
        line-height: (36/28);
    }
    &__text{
        font-size: em(20);
        line-height: (32/20);
        margin-top: em(20,20);
    }
    @media(max-width: 750px){
        padding-right: 0;
        &__title{
            font-size: 24px;
        }
        &__text{
            font-size: 18px;
            margin-top: 20px;
        }
        &__image{
            width: vw(261,375);
            height: vw(120,375);
            padding-bottom: 26px;
        }
    }
}